<template>
 <base-main-container>
    <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          AI is preparing your resource
        </v-progress-circular>
      </v-overlay>
       <template v-slot:centered>
      <v-card class="my-card rounded-lg" elevation="0">
        <v-card-title>TranslatePro: Translate from any language to any language
        
        </v-card-title>
        <v-card-text>
        
           <v-textarea
          
             v-model="inputText"
            outlined
             label= "Please enter the tetx to translate, you can enter text in any language"
          >
          </v-textarea>
         <v-combobox
        
                class="mx-4 mb-n8"
                  v-model="language"
                  :items=languages
                  label="Type or choose any language translate to"
                  
                  
                   @input.native="language=$event.srcElement.value"
                  
                >
               </v-combobox>
               
          
        </v-card-text>
        
        <v-card-actions>
          <v-spacer />
            <v-btn class="mr-2" outlined link to="newresource">
              <v-icon class="mr-2"> mdi-backspace</v-icon> Cancel
            </v-btn>

          <v-btn
            :disabled="! (inputText && language)"
            color="#212121"
            @click="sendRequest()"
          >
            
            <h3  style="color: white">Generate please</h3>
          </v-btn>

          <v-spacer />
        </v-card-actions>
         <v-card-text  v-if="response" class="pa-5">
            <v-divider></v-divider>
             <div 
            
             v-html="response"
             class="custom-font mt-10"
             ></div>
            
          </v-card-text>
      </v-card>
  
       </template>
     
   <v-snackbar
          v-model="showSnackbar"
         :timeout="6000"
           color="warning"
          top
        >
          {{ snackbarMessege }}
        </v-snackbar>
</base-main-container>
</template>

<script>

import { getFunctions, httpsCallable } from "firebase/functions";

export default {
 
  data() {
    return {
      what: 'Translate',
     
      isLoading: false,
      response:null,
      language: null,
      inputText:"",
    
      showSnackbar:false,
      snackbarMessege:"",
      languages :["English","Spanish", "French", "German", "Italian", "Russian", "Chinese", "Japanese",
"Korean", "Arabic", "Turkish", "Hindi"],
      
    };
  },
//   computed:{
//      premium(){
//       return this.$store.state.subscription ? this.$store.state.subscription.status =='active' 
//       || this.$store.state.subscription.status == 'active school'
//        || this.$store.state.subscription.status == 'trialing'
//       : false
//     }
//   },
  methods: {
  
    async sendRequest() {
      this.isLoading = true;
     
      //  let prompt = " can you translate this text to "+this.language + " : " +this.inputText
       
    //  prompt = " I am a teacher and I need help with this simple task, and I need this in rich text format enhaced with html tags, "+prompt



     let  promt = [
      {role: "system",content: "You are an excellent educator, teacher and communicator."},
          {role: "user", content:" can you translate this text to "+this.language + " : " +this.inputText},
        ]

const functions = getFunctions();
 try {
        const functionRef = httpsCallable(functions, "callopenaiForResponse");
        const { data } = await functionRef({
          promt: promt,
        });

        this.response = data.result;
        this.isLoading = false;
       
       } catch (error) {
        this.isLoading = false;
        this.response = null;
       
        this.showSnackbar = true;
        this.snackbarMessege = "The system is overloaded, please try again";
      }
    },
    
    clearSelections() {
      
        this.language = null
        this.inputText = null
       
    },
     
  },
  
};
</script>
<style scoped>
.custom-font {
  font-family: 'Arial', sans-serif; /* Change 'Arial' to the desired font family */
  font-size: 16px;
  font-weight: normal;
  color: black;
  letter-spacing: normal;
   padding: 12px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
           background:#e5e4e4;
          
            border-radius: 10px;
            margin-bottom: 22.5px;
          
  /* Other font properties such as font-size, font-weight, etc. can also be applied here */
}
    .centered {
        border-radius: 15px;  
        background-color:#f3f6fc; 
        display: flex;
        justify-content: center;
        max-width: 1200px;
        height: 90vh; /* Adjust this value to match your desired height */
       
    }
    @media (min-width: 600px) {
  .my-card {
    
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
     
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
    
    width: 800px;
  }
}
    .container.fill-height {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}


</style>




